import { Controller } from "stimulus"

export default class extends Controller {
  static values = { options: Array };
  
  connect() {
    this.index = 0;
    this.element.innerText = this.optionsValue[this.index]; // Affiche la première option
    this.startRotation();
  }

  startRotation() {
    this.interval = setInterval(() => {
      this.index = (this.index + 1) % this.optionsValue.length;
      this.element.innerText = this.optionsValue[this.index];
    }, 1000); // Change toutes les 1 seconde (ajuste selon tes besoins)
  }

  disconnect() {
    clearInterval(this.interval); // Nettoie l'intervalle quand l'élément est retiré
  }
}
