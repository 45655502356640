import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["image"];

  connect() {
    this.observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
            this.observer.unobserve(entry.target); // Stopper l'observation une fois affiché
          }
        });
      },
      { threshold: 0.2 } // Déclenche quand 20% de l’image est visible
    );

    this.imageTargets.forEach((img) => this.observer.observe(img));
  }
}
