import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["display", "select", "summary", "pathologySelect", "mistralForm", "doctorPatientSelect"];

  connect() {
    this.selectTarget.addEventListener("change", this.updateDisplay.bind(this));
    this.updateDisplay();
  }

  disconnect() {
    this.selectTarget.removeEventListener("change", this.updateDisplay.bind(this));
  }

  updateDisplay() {
    const selectedOption = this.selectTarget.options[this.selectTarget.selectedIndex];

    // 🔹 Transcriptions
    const transcription = selectedOption.dataset.transcription || "<p>Sélectionnez un meeting pour voir les transcriptions ici.</p>";
    this.displayTarget.innerHTML = transcription;

    // 🔹 Résumé Mistral
    const mistralSummary = selectedOption.dataset.mistralSummary || "<p>Résumé indisponible pour ce meeting.</p>";
    this.summaryTarget.innerHTML = mistralSummary;

    // ✅ Forcer l'affichage du formulaire uniquement si une transcription est présente
    const formSection = document.getElementById("mistral-form-section");
    if (transcription.includes("transcription")) {
      formSection.style.display = "block";
    } else {
      formSection.style.display = "none";
    }
  }

  async fetchByPathology() {
    const selectedPathologies = Array.from(this.pathologySelectTarget.selectedOptions)
                                      .map(option => option.value);

    if (selectedPathologies.length === 0) {
        this.displayTarget.innerHTML = "<p>Aucune pathologie sélectionnée.</p>";
        return;
    }

    try {
        const response = await fetch(`/mistral_analyses/fetch_transcriptions_by_pathology?pathologies=${selectedPathologies.join(",")}`);
        const data = await response.text();

        this.displayTarget.innerHTML = data || "<p>Aucune transcription trouvée pour ces pathologies.</p>";

        // ✅ Forcer l'affichage du formulaire si des transcriptions sont trouvées
        const formSection = document.getElementById("mistral-form-section");
        formSection.style.display = data.trim() ? "block" : "none";

    } catch (error) {
        console.error("Erreur lors de la récupération des transcriptions :", error);
    }
  }

  loadTranscriptionsByDoctorPatient() {
    const selectedPair = this.doctorPatientSelectTarget.value;
    if (!selectedPair) return;

    const [doctor_id, patient_id] = selectedPair.split("-");

    fetch(`/mistral_analyses/fetch_transcriptions_by_doctor_patient?doctor_id=${doctor_id}&patient_id=${patient_id}`)
      .then(response => response.text())
      .then(data => {
        this.displayTarget.innerHTML = data || "<p>Aucune transcription trouvée pour ce duo médecin-patient.</p>";
        document.getElementById("mistral-form-section").style.display = data.trim() ? "block" : "none";
      })
      .catch(error => console.error("Erreur lors du chargement des transcriptions :", error));
  }


  // ✅ **Récupérer les transcriptions par MÉDECIN & PATIENT**
  async fetchByDoctorPatient() {
    const selectedPair = this.doctorPatientSelectTarget.value;
    if (!selectedPair) return;

    const [doctor_id, patient_id] = selectedPair.split("-");

    try {
        const response = await fetch(`/mistral_analyses/fetch_transcriptions_by_doctor_patient?doctor_id=${doctor_id}&patient_id=${patient_id}`);
        const data = await response.text();

        this.displayTarget.innerHTML = data || "<p>Aucune transcription trouvée pour ce duo médecin-patient.</p>";

        // ✅ Forcer l'affichage du formulaire si des transcriptions sont trouvées
        const formSection = document.getElementById("mistral-form-section");
        formSection.style.display = data.trim() ? "block" : "none";
    } catch (error) {
        console.error("Erreur lors de la récupération des transcriptions :", error);
    }
  }

}
